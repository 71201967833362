import React from "react";
import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => (
  <div className="privacy-policy">
    <h1>Privacy Policy</h1>
    <p>
      Welcome to the <strong>More Life: AI Longevity Coach</strong> app. We are committed to protecting your privacy and ensuring the security of your personal and health data. This Privacy Policy explains how we collect, use, and protect the information you share with us. It also clarifies our compliance with Apple Health and Garmin integration guidelines.
    </p>

    <h2>About Our App</h2>
    <h3>Data Collection</h3>
    <p>
      The More Life app integrates with Apple Health and Garmin to provide personalized longevity insights and coaching. We only collect data that you explicitly authorize and consent to share with us. This includes, but is not limited to:
    </p>
    <ul>
      <li>
        <strong>Apple Health Data</strong>: Steps, activity levels, heart rate, sleep data, and other metrics you permit us to access through HealthKit.
      </li>
      <li>
        <strong>Garmin Data</strong>: Fitness metrics such as running, cycling, and other activity data authorized through your Garmin device and account.
      </li>
    </ul>

    <h3>How We Use Your Data</h3>
    <p>
      The data collected from Apple Health and Garmin is used solely for the following purposes:
    </p>
    <ul>
      <li>
        To provide personalized insights, recommendations, and progress tracking within the app.
      </li>
      <li>
        To analyze your activity trends and deliver an improved user experience.
      </li>
      <li>
        To calculate your longevity and healthspan metrics.
      </li>
    </ul>

    <h3>Prohibited Behavior and Compliance</h3>
    <p>
      The More Life app is designed to comply with all applicable guidelines, including Apple’s HealthKit policies. Specifically:
    </p>
    <ul>
      <li>
        <strong>No Marketing or Advertising:</strong> Health data collected through Apple Health or Garmin is never used for marketing, targeted advertising, or analytics outside of the app’s core functionality.
      </li>
      <li>
        <strong>No Third-Party Sharing:</strong> Your data is never shared with third parties without your explicit consent, except when required by law.
      </li>
      <li>
        <strong>Intended Use Only:</strong> Apple Health data is used exclusively for health and fitness purposes, as required by HealthKit guidelines.
      </li>
    </ul>

    <h3>Data Security</h3>
    <p>
      We implement industry-standard security measures to protect your data, including encryption and secure storage. While no system can guarantee absolute security, we take all reasonable steps to safeguard your information.
    </p>

    <h3>Data Retention</h3>
    <p>
      We retain your data only as long as necessary to fulfill the purposes outlined in this Privacy Policy. If you revoke access to Apple Health or Garmin data, all related information will be deleted from our servers.
    </p>

    <h3>Your Privacy Controls</h3>
    <p>
      You have full control over the data you share with the More Life app. You can:
    </p>
    <ul>
      <li>
        Revoke access to Apple Health data through the Apple Health app on your device.
      </li>
      <li>
        Disconnect your Garmin account at any time through the More Life app settings.
      </li>
      <li>
        Delete your account and all associated data directly within the app.
      </li>
    </ul>

    <h3>Children's Privacy</h3>
    <p>
      The More Life app is not intended for users under the age of 16. We do not knowingly collect personal or health data from children under 16. If you are a parent or guardian and believe your child has provided us with data, please contact us immediately to request deletion.
    </p>

    <h3>Changes to This Privacy Policy</h3>
    <p>
      We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by updating the policy in the app. Please review this policy periodically for updates.
    </p>

    <h3>Contact Us</h3>
    <p>
      If you have any questions about this Privacy Policy or your data, please contact us at{" "}
      <a href="mailto:support@getmorelife.io">support@getmorelife.io</a>. We are here to help and will respond to your inquiries within 30 days.
    </p>
  </div>
);

export default PrivacyPolicy;
