import React from "react";
import "./Landing.scss";
import logo from "../images/logo.png";
import profile from "../images/profile_for_landing.png";
import stravaTilted from "../images/strava_str.png";
import appStoreBadge from "../images/Download_on_the_App_Store_Badge.svg";

import Button from "./reusable/Button";
import config from "../config.json";

const Landing = () => {
  const isProd =
    window.location.href.includes("morelife") ||
    window.location.href.includes("more-life");

  return (
    <div className="page-container">
      {/* Commented out Strava logo
      <img src={stravaTilted} alt="Powered by Strava" className="strava-tilted" />
      */}
      <div className="content-container">
        <h1>
        Train for your <span>future</span> self
          {/* Get <span>more life</span> out of your training */}
        </h1>
        <p className="subheadline">
          Live better, longer with More Life, your AI Longevity Coach
        </p>
        
        <img 
          src={appStoreBadge} 
          alt="Download on the App Store" 
          className="app-store-badge"
          onClick={() => window.location.href = 'https://apps.apple.com/us/app/more-life-ai-longevity-coach/id6739949858'}
          style={{ cursor: 'pointer' }}
        />
        
        <img src={profile} alt="Profile Preview" className="profile-preview" />
        
        {/* Commented out subtitle
        <h2>
          Build your longevity profile and get actionable training advice to live a longer, healthier life.
        </h2>
        */}

        {/* Commented out button section
        {(!isProd || config.showJourneyStartOnProd) && (
          <>
            <div className="button-container">
              <Button
                color="burn"
                cta="Start"
                onClick={() => (window.location.href = "/onboarding")}
              />
            </div>
          </>
        )}
        */}
      </div>

      <footer>
        <p>&copy; More Life 2024. All rights reserved.</p>
        <p>
          <a href="/privacy-policy">Privacy Policy</a> {"  "}
          <a href="/support">Support</a>
          {/* | */}
          {/* <a href="mailto:contact@example.com">Contact</a> */}
        </p>
      </footer>
    </div>
  );
};

export default Landing;
